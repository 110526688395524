import 'lodash.isempty';
import 'lodash.isobject';

// Temporarily disabling since this is originally a JavaScript that needed to be
// migrated to TypeScript for exports to work as correctly in Vite.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

function fontStack(fonts) {
  return fonts.map(font => font.includes(' ') ? `"${font}"` : font).join(', ');
}

export { fontStack };
