import { isMacOS } from '@primer/behaviors/utils';
import { useState, useEffect } from 'react';
import { canUseDOM } from '../utils/environment.js';

/**
 * SSR-safe hook for determining if the current platform is MacOS. When rendering
 * server-side, will default to non-MacOS and then re-render in an effect if the
 * client turns out to be a MacOS device.
 */
function useIsMacOS() {
  const [isMacOS$1, setIsMacOS] = useState(() => canUseDOM ? isMacOS() : false);
  useEffect(() => setIsMacOS(isMacOS()), []);
  return isMacOS$1;
}

export { useIsMacOS };
